import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import moment from "moment";

import { StaticImage } from "gatsby-plugin-image";

const ReviewCard = ({ review }) => {
	var trimmedString = review.reviewBody.substr(0, 150);
	trimmedString =
		trimmedString.substr(
			0,
			Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
		) + "...";

	const [reviewBody, setReviewBody] = useState(review.reviewBody);
	const [linkText, setlinkText] = useState("Read More");
	const [expanded, setExpanded] = useState(false);

	const handleClick = () => {
		console.log("clicked");
		if (!expanded) {
			setReviewBody(review.reviewBody);
			setlinkText("Read Less");
			setExpanded(true);
		} else {
			setReviewBody(trimmedString);
			setlinkText("Read More");
			setExpanded(false);
		}
	};
	if (!expanded) setReviewBody(trimmedString);
	return (
		<Card className="border-0 review-card  my-4 mx-0">
			<Card.Header className="bg-white border-0">
				<Row className="align-items-center">
					<Col xs={9}>
						<Card.Title className="montserrat-semi-bold mb-0 mb-md-1 mb-lg-0">
							{review.author}
						</Card.Title>
						<p className="pb-0 mb-0" style={{ fontSize: "1.2rem" }}>
							{moment(review.datePublished).format("YYYY-MM-DD")}
						</p>
					</Col>
					<Col xs={3} className="pt-2">
						{review.source === "google" && (
							<StaticImage
								src="../images/googlereview.png"
								className="review-image"
								alt="5 star review of local locksmith"
								placeholder="tracedSVG"
								quality="100"
								width={60}
								formats={["auto", "webp"]}
							/>
						)}
						{review.source === "facebook" && (
							<StaticImage
								src="../images/facebook.png"
								className="review-image"
								alt="5 star review of local locksmith"
								placeholder="tracedSVG"
								quality="100"
								width={60}
								formats={["auto", "webp"]}
							/>
						)}
						{review.source === "yell" && (
							<StaticImage
								src="../images/yell-icon.jpeg"
								className="review-image"
								alt="5 star review of local locksmith"
								placeholder="tracedSVG"
								quality="100"
								width={60}
								formats={["auto", "webp"]}
							/>
						)}
					</Col>
					<Row className="pt-1  mt-0 ">
						<Col className=" d-flex mx-0 text-center">
							{" "}
							<StaticImage
								className="me-1"
								quality="100"
								src="../images/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>{" "}
							<StaticImage
								className="me-1"
								quality="100"
								src="../images/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>{" "}
							<StaticImage
								className="me-1"
								quality="100"
								src="../images/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>{" "}
							<StaticImage
								className="me-1"
								quality="100"
								src="../images/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>{" "}
							<StaticImage
								quality="100"
								src="../images/star.svg"
								alt="star"
								placeholder="blurred"
								width={15}
								formats={["auto", "webp"]}
							/>
						</Col>
					</Row>
				</Row>
			</Card.Header>
			<Card.Body>
				<Row>
					<Col>
						<Card.Text>
							<p>
								<span>"</span>
								{reviewBody}
								<span>"</span>
							</p>
						</Card.Text>
					</Col>
				</Row>
				<Row>
					<Col>
						<p
							className="review-link fw-bold pointer d-inline-block"
							onClick={handleClick}
						>
							{linkText}
						</p>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default ReviewCard;
